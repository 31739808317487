body{
  /* background-color: #000; */
  font-family: "Raleway", sans-serif;
}
p{
  margin: 0;
  padding: 0;
}
.calendar-container {
  display: grid;
  /* grid-template-columns: auto repeat(31, 1fr); */
  position: relative;
  width: 270%;
}

.calendar-header, .calendar-row {
  display: contents;
}

.employee-header, .employee-name, .day-header, .day-cell {
  padding: 5px;
  text-align: center;
  position: relative;
}

.employee-name{
  color: #d3a188;
  text-decoration: none;
  border-bottom: 0.5px solid #000;
}
.employee-header {
  grid-column: 1 / 2;
  background-color: #f0f0f0;
  font-weight: bold;
}

.day-header, .day-cell {
  border: 0.5px solid black;
}

.available {
  background-color: #c3e7c3; 
}

.unavailable {
  background-color: white;
}

.current-day-cell {
  border: 1px solid red;
  box-sizing: border-box;
}

.current-day {
  border: 1px solid red;
  box-sizing: border-box;
}
.spin-loading{
  
}


.progress-container {
  padding: 20px;
}
.progress-container tr.progress-bar:hover {
  background: rgba(211, 161, 136, 0.2);
}


/* .progress {
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  position: relative;
} */

.progress-value {
  background-color: #d3a188;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: width 0.3s ease;
}
.total{
  width: 100%;
  border: 1px solid #d3a188;
}

.chart-container button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}



.progress-container table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-family: 'Raleway';
}

.progress-container td, .progress-container th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.progress-container table a{
  color: #d3a188;
  text-decoration: none;
}
.progress-container .proc{
  color: #d3a188;
}
.chart-container button {
  background: #d3a188;
  border: 1px solid transparent;
  transition: 0.5s;
  font-family: 'Raleway';
}
.chart-container button:hover {
  border-color: #d3a188;
  background: #fff;
}
/* tr:nth-child(even) {
  background-color: #dddddd;
} */



.chart-container input[type="date"] {
  background-color: #d3a188;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px;
  font-size: 16px;
  color: #333;
  width: 200px;
  cursor: pointer;
  margin-left: 20px;
  transition: 0.5s;
}
.chart-container input[type="date"]:hover{
  border-color: #d3a188;
  background: #fff;
}

.chart-container input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: rgba(211, 161, 136, 0.2); /* Ваш цвет */
  border-radius: 50%;
  padding: 5px;
}

.chart-container input[type="date"]::-webkit-inner-spin-button,
.chart-container input[type="date"]::-webkit-clear-button {
  display: none;
}

.filters .select-manager{
  background: #d3a188;
  border: 1px solid transparent;
  transition: 0.5s;
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.filters{
  margin-bottom: 20px;
  display: flex;
}
.filters input[type="month"] {
  background-color: #d3a188;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0 6px;
  font-size: 16px;
  color: #333;
  width: 200px;
  cursor: pointer;
  margin-left: 20px;
  transition: 0.5s;
  margin: 5px 5px 5px 10px;
}
.filters input[type="month"]:hover{
  border-color: #d3a188;
  background: #fff;
}

.filters input[type="month"]::-webkit-calendar-picker-indicator {
  background-color: rgba(211, 161, 136, 0.2); /* Ваш цвет */
  border-radius: 50%;
  padding: 5px;
}

.filters input[type="month"]::-webkit-inner-spin-button,
.filters input[type="month"]::-webkit-clear-button {
  display: none;
}
.spin-styles {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 52px);
  width: 100vw;
}
.spin-styles i.ant-spin-dot-item {
  background: #d3a188 !important;
}

.calendar-row:hover .day-cell:after {
  content: "";
  background: rgba(211, 161, 136, 0.2);
  /* border-top: 1px solid red; */
  /* border-bottom: 1px solid red; */
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.calendar-row:hover .employee-name:after {
  content: "";
  background: rgba(211, 161, 136, 0.2);
  /* border-top: 1px solid red; */
  /* border-bottom: 1px solid red; */
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.filter-chart{
  display: flex;
  align-items: center;
}

.filter-chart .info {
  display: flex;
  gap: 20px;
  border-radius: 5px;
  font-size: 16px;
  margin-left: auto;
  margin-right: 20px;
}

.filter-chart .info p {
  padding: 11px;
}
.filter-chart .info p span{
  font-weight: bold;
}
.calendar-container .day-cell span.unknown-location {
  color: #ff0000;
  font-weight: bold;
}

.back-arrow{
  background-color: #d3a188;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: 0.5s;
  margin: 5px 10px 5px 0px;
  display: flex;
}

.cell-time-in, .cell-time-out {
  font-weight: bold;
  font-family: sans-serif;
}